import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Button.css'

export function Button({
  onClick,
  tabIndex = 0,
  label = undefined,
  icon = undefined,
  dataX = undefined,
  ariaLabel = undefined,
  layoutClassName = undefined
}) {
  return (
    <button
      type='button'
      data-x={dataX}
      aria-label={ariaLabel}
      className={cx(styles.component, layoutClassName)}
      {...{ tabIndex, onClick }}
    >
      {label && <span className={styles.label}>{label}</span>}
      {icon && <Icon {...{ icon }} layoutClassName={styles.iconLayout} />}
    </button>
  )
}
