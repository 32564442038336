import { useNavigationContext } from '/features/pageOnly/menu/NavigationContext'
import { animated, useTransition } from '@react-spring/web'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { useMediaQuery } from '@kaliber/use-media-query'

import { routeMap } from '/routeMap'

import ImageCover from '/features/buildingBlocks/ImageCover.universal'
import { Icon } from '/features/buildingBlocks/Icon'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Submenu.css'

import iconArrow from '/images/icons/arrow.raw.svg'

export function SubmenuDesktop({ items, isActive, getItemProps, layoutClassName }) {
  const isViewportXxl = useMediaQuery(mediaStyles.viewportXxl)
  const chunkSize = isViewportXxl ? 3 : 2

  const chunks = Array.from({ length: Math.ceil(items.length / chunkSize) }, (_, i) =>
    items.slice(i * chunkSize, i * chunkSize + chunkSize)
  )

  const transitions = useTransition(chunks, {
    delay: 150,
    trail: 75,
    from: { opacity: 0, y: -5 },
    enter: { opacity: 1, y: 0 },
    config: { tension: 500, friction: 50 }
  })

  return (
    <ul className={cx(styles.componentDesktop, layoutClassName)}>
      {transitions((style, items) => (
        <animated.li className={styles.listItemDesktop} {...{ style }}>
          {items.filter(x => x.ref).map(({ ref, label, subtitle, image }, i) => (
            <SubmenuItem key={i} linkRef={ref}
              {...{ label, subtitle, image, isActive, getItemProps }}
            />
          ))}
        </animated.li>
      ))}
    </ul>
  )
}

export function SubmenuMobile({ parent, items }) {
  const menuItems = [parent, ...items].filter(x => x.ref)

  const transitions = useTransition(menuItems, {
    delay: 150,
    trail: 75,
    from: { opacity: 0, y: -5 },
    enter: { opacity: 1, y: 0 },
    config: { tension: 500, friction: 50 }
  })

  return (
    <ul className={styles.componentMobile}>
      {transitions((style, { ref, label, subtitle, image }) => (
        <animated.li className={styles.listItemMobile} {...{ style }}>
          <SubmenuItem linkRef={ref} {...{ label, subtitle, image }} />
        </animated.li>
      ))}
    </ul>
  )
}

function SubmenuItem({
  linkRef,
  label,
  image,
  isActive = undefined,
  subtitle = undefined,
  getItemProps = undefined,
}) {
  return (
    <div className={styles.componentItem}>
      {image && (
        <div className={styles.image}>
          <ImageCover aspectRatio={1 / 1} layoutClassName={styles.imageLayout} {...{ image }} />
        </div>
      )}

      <ItemDetails
        layoutClassName={styles.itemDetailsLayout}
        {...{ linkRef, label, subtitle, isActive, getItemProps }}
      />

      <span className={styles.arrowIconContainer}>
        <Icon icon={iconArrow} />
      </span>
    </div>
  )
}

function ItemDetails({ linkRef, label, subtitle, isActive, getItemProps, layoutClassName = undefined }) {
  const { menuIsExpanded } = useNavigationContext()

  const href = linkRef ? determineDocumentPathSync({ document: linkRef, routeMap }) : undefined

  return (
    <div className={cx(styles.componentItemDetails, layoutClassName)}>
      <h3 className={styles.title}>
        <a
          data-x='link-in-menu'
          className={styles.anchor}
          tabIndex={(isActive && menuIsExpanded) ? 0 : -1}
          {...getItemProps?.()}
          {...{ href }}
        >
          {label}
        </a>
      </h3>

      {subtitle && (
        <div className={styles.subtitleContainer}>
          <span className={styles.subtitle}>{subtitle}</span>
        </div>
      )}
    </div>
  )
}
