import { useFloating, useDismiss, useInteractions, autoUpdate } from '@floating-ui/react'
import { animated, useTransition } from '@react-spring/web'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'

import { routeMap } from '/routeMap'
import { useTranslate } from '/machinery/I18n'

import { useNavigationContext } from '/features/pageOnly/menu/NavigationContext'
import { Hamburger } from '/features/pageOnly/menu/buildingBlocks/Hamburger'
import { Logo } from '/features/pageOnly/menu/buildingBlocks/Logo'
import { ExpandHorizontal, ExpandVertical } from '/features/buildingBlocks/Expand'
import { SubmenuMobile } from '/features/pageOnly/menu/buildingBlocks/Submenu'
import { Button } from '/features/pageOnly/menu/buildingBlocks/Button'
import { LanguageSwitch } from '/features/pageOnly/menu/buildingBlocks/LanguageSwitch'
import { ShowSkillsMatchPortalFlowButton } from '/features/pageOnly/skillsMatch/buildingBlocks/ShowSkillsMatchPortalFlowButton'
import { Icon } from '/features/buildingBlocks/Icon'
import { ButtonLinkPrimary } from '/features/buildingBlocks/Button'

import styles from './MenuMobile.css'

import iconClose from '/images/icons/close.raw.svg'
import iconChevron from '/images/icons/chevron.raw.svg'

export function MenuMobile({ items, logoHref, fixedButtons, skillsMatchUserSelectionCount, showSkillsMatchButton }) {
  const { refs, getReferenceProps } = useFloatingProps()

  const { menuIsExpanded, mobileMenuIsExpanded } = useNavigationContext()
  const menuIsFullWidth = menuIsExpanded || mobileMenuIsExpanded || Boolean(fixedButtons.length)

  return (
    <nav className={styles.component}>
      <div ref={refs.setReference} className={cx(styles.navBarContainer, menuIsFullWidth && styles.menuIsFullWidth)} {...getReferenceProps()}>
        <NavBar
          {...{
            items,
            logoHref,
            fixedButtons,
            skillsMatchUserSelectionCount,
            showSkillsMatchButton,
          }}
        />
      </div>
    </nav>
  )
}

function NavBar({ items, logoHref, fixedButtons, skillsMatchUserSelectionCount, showSkillsMatchButton }) {
  const { menuIsExpanded, mobileMenuIsExpanded } = useNavigationContext()
  const menuIsFullWidth = menuIsExpanded || mobileMenuIsExpanded || Boolean(fixedButtons.length)

  return (
    <div className={cx(styles.componentNavBar, menuIsFullWidth && styles.menuIsFullWidth)}>
      {menuIsFullWidth && <LogoAndButtons layoutClassName={styles.logoAndButtonsLayout} {...{ logoHref, fixedButtons }} />}
      <HamburgerAndLanguageSwitch layoutClassName={styles.hamburgerAndLanguageSwitchLayout} />
      {mobileMenuIsExpanded && <Navigation layoutClassName={styles.navigationLayout} {...{ items, skillsMatchUserSelectionCount, showSkillsMatchButton }} />}
    </div>
  )
}

function Navigation({ items, skillsMatchUserSelectionCount, showSkillsMatchButton, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const { activeSubmenu, submenuIsOpen } = useNavigationContext()

  return (
    <div className={cx(styles.componentNavigation, layoutClassName)}>
      <ExpandVertical expanded>
        {submenuIsOpen
          ? <SubmenuMobile parent={activeSubmenu} items={activeSubmenu.items} />
          : <Menu {...{ items }} />
        }

        {showSkillsMatchButton && (
          <div className={styles.skillsButtonContainer}>
            <ShowSkillsMatchPortalFlowButton
              label={skillsMatchUserSelectionCount > 0 ? __`my-skills` : __`what-are-your-skills`}
              buttonType='secondary'
              layoutClassName={styles.skillsButtonLayout}
              dataX='link-in-menu-to-skillsmatchportal'
            />
          </div>
        )}
      </ExpandVertical>
    </div>
  )
}

function Menu({ items }) {
  const transitions = useTransition(items, {
    keys: x => x._key,
    trail: 75,
    delay: 150,
    from: { opacity: 0, y: -5 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 0 },
    config: { tension: 500, friction: 50 }
  })

  return (
    <ul className={styles.componentMenu}>
      {transitions((style, item) => (
        <animated.li className={styles.menuItem} key={item._key} {...{ style }}>
          <MenuItem {...{ item }} />
        </animated.li>
      ))}
    </ul>
  )
}

function MenuItem({ item }) {
  switch (item?._type) {
    case 'referenceWithLabel': return <MenuLink {...{ item }} />
    case 'submenu': return <SubmenuButton {...{ item }} />
    default: return null
  }
}

function MenuLink({ item }) {
  const { ref, label, isActive } = item

  return (
    <a
      data-x='link-in-menu'
      href={determineDocumentPathSync({ document: ref, routeMap })}
      className={cx(styles.componentMenuLink, isActive && styles.isActive)}
    >
      {label}
    </a>
  )
}

function SubmenuButton({ item }) {
  const { onActiveSubmenuChange } = useNavigationContext()
  const { id, ref, label, submenuItems, isActive } = item

  return (
    <button
      type='button'
      data-x='click-to-open-submenu'
      onClick={handleClick}
      className={cx(styles.componentSubmenuButton, isActive && styles.isActive)}
    >
      {label}
    </button>
  )

  function handleClick() {
    onActiveSubmenuChange({ id, ref, active: true, label, items: submenuItems })
  }
}

function HamburgerAndLanguageSwitch({ layoutClassName = undefined }) {
  const { translations, mobileMenuIsExpanded, setMobileMenuIsExpanded, onActiveSubmenuChange } = useNavigationContext()

  return (
    <div className={cx(styles.componentHamburgerAndLanguageSwitch, layoutClassName)}>
      <ExpandHorizontal justification='end' expanded={!mobileMenuIsExpanded}>
        <Hamburger onClick={() => setMobileMenuIsExpanded(true)} />
      </ExpandHorizontal>

      <ExpandHorizontal expanded={mobileMenuIsExpanded}>
        <div className={styles.navigationButtons}>
          <LanguageSwitch {...{ translations }} />
          <Button icon={iconClose} onClick={handleClose} dataX='click-to-close-menu' />
        </div>
      </ExpandHorizontal>
    </div>
  )

  function handleClose() {
    setMobileMenuIsExpanded(false)
    onActiveSubmenuChange({ id: undefined, ref: undefined, active: false, label: undefined, items: undefined })
  }
}

function LogoAndButtons({ logoHref, fixedButtons, layoutClassName = undefined }) {
  const { menuIsExpanded, mobileMenuIsExpanded, submenuIsOpen } = useNavigationContext()

  return (
    <div className={cx(styles.componentLogoAndButtons, layoutClassName)}>
      <ExpandHorizontal expanded={(menuIsExpanded || mobileMenuIsExpanded) && !submenuIsOpen} layoutClassName={styles.logoExpandLayout}>
        <div className={styles.logoContainer}>
          <Logo href={logoHref} layoutClassName={styles.logoLayout} />
        </div>
      </ExpandHorizontal>

      <ExpandHorizontal expanded={!(menuIsExpanded || mobileMenuIsExpanded || submenuIsOpen)}>
        {fixedButtons?.map(({ href, label, dataX }, i) => (
          <span key={i} className={styles.fixedButtonContainer}>
            <ButtonLinkPrimary {...{ href, label, dataX }} />
          </span>
        ))}
      </ExpandHorizontal>

      <ExpandHorizontal expanded={mobileMenuIsExpanded && submenuIsOpen}>
        {submenuIsOpen && <BackButton />}
      </ExpandHorizontal>
    </div>
  )
}

function BackButton() {
  const { activeSubmenu, onActiveSubmenuChange } = useNavigationContext()

  return (
    <button type='button' data-x='close-submenu' onClick={handleClick} className={styles.componentBackButton}>
      <div className={styles.icon}>
        <Icon icon={iconChevron} />
      </div>
      <span className={styles.label}>{activeSubmenu.label}</span>
    </button>
  )

  function handleClick() {
    onActiveSubmenuChange({ id: undefined, active: false, label: undefined, items: undefined })
  }
}

function useFloatingProps() {
  const { mobileMenuIsExpanded, setMobileMenuIsExpanded } = useNavigationContext()

  const { refs, context } = useFloating({
    open: mobileMenuIsExpanded,
    onOpenChange: setMobileMenuIsExpanded,
    whileElementsMounted: autoUpdate,
  })

  const dismiss = useDismiss(context, {
    referencePress: false,
    outsidePress: true
  })

  const { getReferenceProps } = useInteractions([dismiss])

  return { refs, getReferenceProps }
}
