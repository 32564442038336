import { useEvent } from '/machinery/useEvent'

export function useScrollDirection({ deltaThreshold, scrollThreshold, callback }) {
  const callbackEvent = useEvent(callback)

  React.useEffect(
    () => {
      window.addEventListener('resize', handleResize)

      return () => window.removeEventListener('resize', handleResize)

      function handleResize() {
        callbackEvent({ direction: 0 })
      }
    },
    [callbackEvent]
  )

  React.useEffect(
    () => {
      let scrollY = window.scrollY

      handleScroll()

      window.addEventListener('scroll', handleScroll)

      return () => window.removeEventListener('scroll', handleScroll)

      function handleScroll() {
        const deltaY = window.scrollY - scrollY
        const direction = Math.sign(deltaY)
        const difference = Math.abs(deltaY)
        const metDeltaThreshold = difference >= deltaThreshold
        const metScrollThreshold = window.scrollY > scrollThreshold

        if (metDeltaThreshold) {
          scrollY = window.scrollY
        }

        if (metScrollThreshold && !metDeltaThreshold) return

        callbackEvent({ direction: metScrollThreshold ? direction : 0, metScrollThreshold })
      }
    },
    [deltaThreshold, scrollThreshold, callbackEvent]
  )
}
