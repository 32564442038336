import LogoRabo from '/images/rabobank.svg'

import styles from './Logo.css'

export function Logo({ href, layoutClassName = undefined }) {
  return (
    <a className={cx(styles.component, layoutClassName)} data-x='link-in-menu-to-home' {...{ href }}>
      <img draggable={false} className={styles.logo} src={LogoRabo} alt="Logo Rabobank" />
    </a>
  )
}
